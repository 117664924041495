export const TIME_LIST = [
  "7:00AM",
  "8:00AM",
  "9:00AM",
  "10:00AM",
  "11:00AM",
  "12:00PM",
  "1:00PM",
  "2:00PM",
  "3:00PM",
  "4:00PM",
  "5:00PM",
  "6:00PM",
  "7:00PM",
  "8:00PM",
  "9:00PM",
  "10:00PM",
  "11:00PM",
  "12:00AM",
  "1:00AM",
  "2:00AM",
  "3:00AM",
  "4:00AM",
  "5:00AM",
  "6:00AM"
];

export const INFRACTION_TYPE = [
  "isBrakesChecked",
  "isCouplingDevicesChecked",
  "isExhaustChecked",
  "isBodyFrameChecked",
  "isFuelSystemChecked",
  "isLightsChecked",
  "isLoadSecurityChecked",
  "isSteeringChecked",
  "isSuspensionChecked",
  "isTiresChecked",
  "isWheelsRimsChecked",
  "isWipersChecked",
  "isOtherChecked"
];

export const INFRACTION_LABLES = [
  "Brakes",
  "Coupling Devices",
  "Exhaust",
  "Body/Frame",
  "Fuel System:",
  "Lights",
  "Load Security",
  "Steering",
  "Suspension",
  "Tires",
  "Wheels/Rims",
  "Wipers",
  "Other"
];

export const DEFAULT_VALUES_INFRACTION = [
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0"
];

export const DEFAULT_VALUES_TIME = [
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0"
];

export const CAR_MAKE = [
  { name: "Acura", id: "Acura" },
  { name: "Alfa Romeo", id: "Alfa Romeo" },
  { name: "Aston Martin", id: "Aston Martin" },
  { name: "Audi", id: "Audi" },
  { name: "Austin-Healey", id: "Austin-Healey" },
  { name: "Bentley", id: "Bentley" },
  { name: "BMW ", id: "BMW " },
  { name: "Buick ", id: "Buick " },
  { name: "Cadillac ", id: "Cadillac " },
  { name: "Caterham ", id: "Caterham " },
  { name: "Chevrolet", id: "Chevrolet" },
  { name: "Chrysler ", id: "Chrysler " },
  { name: "Datsun ", id: "Datsun " },
  { name: "Dodge or Ram ", id: "Dodge or Ram " },
  { name: "Ferrari", id: "Ferrari" },
  { name: "Fiat ", id: "Fiat " },
  { name: "Fisker", id: "Fisker" },
  { name: "Ford ", id: "Ford " },
  { name: "Genesis ", id: "Genesis " },
  { name: "GMC ", id: "GMC " },
  { name: "Hino ", id: "Hino " },
  { name: "Honda ", id: "Honda " },
  { name: "Hummer", id: "Hummer" },
  { name: "Hyundai ", id: "Hyundai " },
  { name: "Infiniti", id: "Infiniti" },
  { name: "Isuzu", id: "Isuzu" },
  { name: "Jaguar ", id: "Jaguar " },
  { name: "Jeep", id: "Jeep" },
  { name: "Kia", id: "Kia" },
  { name: "Lamborghini ", id: "Lamborghini " },
  { name: "Land Rover ", id: "Land Rover " },
  { name: "Lexus ", id: "Lexus " },
  { name: "Lincoln ", id: "Lincoln " },
  { name: "Lotus ", id: "Lotus " },
  { name: "Maserati ", id: "Maserati " },
  { name: "Maybach ", id: "Maybach " },
  { name: "Mazda", id: "Mazda" },
  { name: "McLaren ", id: "McLaren " },
  { name: "Mercedes-Benz", id: "Mercedes-Benz" },
  { name: "Mercury", id: "Mercury" },
  { name: "MG", id: "MG" },
  { name: "MINI", id: "MINI" },
  { name: "Mitsubishi", id: "Mitsubishi" },
  { name: "Nissan", id: "Nissan" },
  { name: "Oldsmobile", id: "Oldsmobile" },
  { name: "Plymouth", id: "Plymouth" },
  { name: "Pontiac", id: "Pontiac" },
  { name: "Porsche", id: "Porsche" },
  { name: "Rolls-Royce ", id: "Rolls-Royce " },
  { name: "Saab", id: "Saab" },
  { name: "Saturn ", id: "Saturn " },
  { name: "Scion ", id: "Scion " },
  { name: "Shelby ", id: "Shelby " },
  { name: "Subaru", id: "Subaru" },
  { name: "Suzuki", id: "Suzuki" },
  { name: "Tesla", id: "Tesla" },
  { name: "Toyota", id: "Toyota" },
  { name: "Triumph", id: "Triumph" },
  { name: "Volkswagen", id: "Volkswagen" },
  { name: "Volvo", id: "Volvo" }
];

export const TIME = [
  { name: "7:00AM", id: "7:00AM" },
  { name: "8:00AM", id: "8:00AM" },
  { name: "9:00AM", id: "9:00AM" },
  { name: "10:00AM", id: "10:00AM" },
  { name: "11:00AM", id: "11:00AM" },
  { name: "12:00PM", id: "12:00PM" },
  { name: "1:00PM", id: "1:00PM" },
  { name: "2:00PM", id: "2:00PM" },
  { name: "3:00PM", id: "3:00PM" },
  { name: "4:00PM", id: "4:00PM" },
  { name: "5:00PM", id: "5:00PM" },
  { name: "6:00PM", id: "6:00PM" },
  { name: "7:00PM", id: "7:00PM" },
  { name: "8:00PM", id: "8:00PM" },
  { name: "9:00PM", id: "9:00PM" },
  { name: "10:00PM", id: "10:00PM" },
  { name: "11:00PM", id: "11:00PM" },
  { name: "12:00AM", id: "12:00AM" },
  { name: "1:00AM", id: "1:00AM" },
  { name: "2:00AM", id: "2:00AM" },
  { name: "3:00AM", id: "3:00AM" },
  { name: "4:00AM", id: "4:00AM" },
  { name: "5:00AM", id: "5:00AM" },
  { name: "6:00AM", id: "6:00AM" }
];

export const YEAR = [
  { name: "2020", id: "2020" },
  { name: "2019", id: "2019" },
  { name: "2018", id: "2018" },
  { name: "2017", id: "2017" },
  { name: "2016", id: "2016" },
  { name: "2015", id: "2015" },
  { name: "2014", id: "2014" },
  { name: "2013", id: "2013" },
  { name: "2012", id: "2012" },
  { name: "2011", id: "2011" },
  { name: "2010", id: "2010" },
  { name: "2009", id: "2009" },
  { name: "2008", id: "2008" },
  { name: "2007", id: "2007" },
  { name: "2006", id: "2006" },
  { name: "2005", id: "2005" },
  { name: "2004", id: "2004" },
  { name: "2003", id: "2003" },
  { name: "2002", id: "2002" },
  { name: "2001", id: "2001" },
  { name: "2000", id: "2000" },
  { name: "1999", id: "1999" },
  { name: "1998", id: "1998" },
  { name: "1997", id: "1997" },
  { name: "1996", id: "1996" },
  { name: "1995", id: "1995" },
  { name: "1994", id: "1994" },
  { name: "1993", id: "1993" },
  { name: "1992", id: "1992" },
  { name: "1991", id: "1991" },
  { name: "1990", id: "1990" },
  { name: "1989", id: "1989" },
  { name: "1988", id: "1988" },
  { name: "1987", id: "1987" },
  { name: "1986", id: "1986" },
  { name: "1985", id: "1985" },
  { name: "1984", id: "1984" },
  { name: "1983", id: "1983" },
  { name: "1982", id: "1982" },
  { name: "1981", id: "1981" },
  { name: "1980", id: "1980" },
  { name: "1979", id: "1979" },
  { name: "1978", id: "1978" },
  { name: "1977", id: "1977" },
  { name: "1976", id: "1976" },
  { name: "1975", id: "1975" },
  { name: "1974", id: "1974" },
  { name: "1973", id: "1973" },
  { name: "1972", id: "1972" },
  { name: "1971", id: "1971" },
  { name: "1970", id: "1970" },
  { name: "1969", id: "1969" },
  { name: "1968", id: "1968" },
  { name: "1967", id: "1967" },
  { name: "1966", id: "1966" },
  { name: "1965", id: "1965" },
  { name: "1964", id: "1964" },
  { name: "1963", id: "1963" },
  { name: "1962", id: "1962" },
  { name: "1961", id: "1961" },
  { name: "1960", id: "1960" },
  { name: "1959", id: "1959" },
  { name: "1958", id: "1958" },
  { name: "1957", id: "1957" },
  { name: "1956", id: "1956" },
  { name: "1955", id: "1955" },
  { name: "1954", id: "1954" },
  { name: "1953", id: "1953" },
  { name: "1952", id: "1952" },
  { name: "1951", id: "1951" },
  { name: "1950", id: "1950" },
  { name: "1949", id: "1949" },
  { name: "1948", id: "1948" },
  { name: "1947", id: "1947" },
  { name: "1946", id: "1946" },
  { name: "1945", id: "1945" },
  { name: "1944", id: "1944" },
  { name: "1943", id: "1943" },
  { name: "1942", id: "1942" },
  { name: "1941", id: "1941" },
  { name: "1940", id: "1940" },
  { name: "1939", id: "1939" },
  { name: "1938", id: "1938" },
  { name: "1937", id: "1937" },
  { name: "1936", id: "1936" },
  { name: "1935", id: "1935" },
  { name: "1934", id: "1934" },
  { name: "1933", id: "1933" },
  { name: "1932", id: "1932" },
  { name: "1931", id: "1931" },
  { name: "1930", id: "1930" },
  { name: "1929", id: "1929" },
  { name: "1928", id: "1928" },
  { name: "1927", id: "1927" },
  { name: "1926", id: "1926" },
  { name: "1925", id: "1925" },
  { name: "1924", id: "1924" },
  { name: "1923", id: "1923" },
  { name: "1922", id: "1922" }
];

export const POLICE_OFFICER_LIST = [
  { id: "Cain", name: "Cain" },
  { id: "Christensen", name: "Christensen" },
  { id: "Bercic", name: "Bercic" },
  { id: "Colville", name: "Colville" },
  { id: "Shea", name: "Shea" },
  { id: "Kim", name: "Kim" },
  { id: "Buckingham", name: "Buckingham" },
  { id: "Other", name: "Other" }
];

export const POLICE_VEHICLE_LIST = [
  { id: "Police Car", name: "Police Car" },
  { id: "Motorcycle", name: "Motorcycle" },
  { id: "SUV", name: "SUV" },
  { id: "Under Cover", name: "Under Cover" },
  { id: "None", name: "None" }
];

export const VI_TYPE_LIST = [
  { id: "Box1", name: "Box1" },
  { id: "Box2", name: "Box2" },
  { id: "Box3", name: "Box3" }
];

export const YES_NO_LIST = [
  { id: "Yes", name: "Yes" },
  { id: "No", name: "No" }
];

export const LENGTH_OF_TIME = [
  { id: "10 Minutes", name: "10 Minutes" },
  { id: "30 Minutes", name: "30 Minutes" },
  { id: "45 Minutes", name: "45 Minutes" },
  { id: "1 Hour", name: "1 Hour" },
  { id: "1+ Hour", name: "1+ Hour" }
];

export const TICKET_TYPE_LIST = [
  { id: "Brakes", name: "Brakes" },
  { id: "Coupling Devices", name: "Coupling Devices" },
  { id: "Exhaust", name: "Exhaust" },
  { id: "Fuel System", name: "Fuel System" },
  { id: "Lights", name: "Lights" },
  { id: "Load Security", name: "Load Security" },
  { id: "Suspension", name: "Suspension" },
  { id: "Tires", name: "Tires" },
  { id: "Wheels/Rims", name: "Wheels/Rims" },
  { id: "Wipers", name: "Wipers" },
  { id: "Other", name: "Other" }
];
